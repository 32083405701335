/* @import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap"); */

*,
*::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
}

img {
  max-width: 100%;
}

html {
  font-size: 62.5%;
}

.navbar {
  background-color: #fff !important;
}

.navbar-brand img {
  width: 9vw;
  height: 9vw;
  max-width: 100%;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
  margin-left: 1.5em;
}

@keyframes float {
  0% {
    transform: translatey(0rem);
  }
  50% {
    transform: translatey(-0.8rem);
  }
  100% {
    transform: translatey(0);
  }
}

.navbar a {
  font-size: 2rem;
  text-transform: capitalize;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.menu_active {
  font-weight: bold;
}

.nav-item {
  margin: 1rem;
  text-decoration: 1px solid #000;
}

.nav-item:hover {
  color: rgba(37, 37, 223, 0.767) !important;
  border: 0.5px solid #3498db !important;
  cursor: pointer;
}

.dropdown-item {
  background-color: white !important;
  color: #000000 !important;
  font-size: 1.5rem !important;
  padding: 0.5em;
}

/* .dropdown-item:hover {
  text-decoration: underline solid 0.2rem;
} */

/* Home */
.home {
  padding: 0;
}

#videoDiv2 {
  width: 100%;
  margin: auto;
  display: block;
  position: relative;
}

#video2 {
  width: 100%;
  height: auto;
}

#videoMessage2 {
  position: absolute;
  top: 1vw;
  display: flex;
  color: #f1f1f1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.content {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  height: 95%;
  top: 1.5em;
}

.carousel-item {
  margin-left: 12vw;
  width: 65%;
}

.carousel-item h1 {
  font-weight: bolder;
  font-size: calc(3vw + 1.2px);
  padding: 0.4em;
  margin-top: 1.5em;
  letter-spacing: 0.1rem;
}

.carousel-item h3 {
  font-size: calc(1.1vw + 0.5rem);
  padding: 0.2em 0 0 0.8em;
}

.talkBtn {
  position: relative;
  margin-left: 60%;
  /* margin-top: 2%; */
}

.talkBtn a {
  font-size: calc(1.2vw + 0.3em);
  margin: 1em;
}

/* about us */
.aboutUs {
  height: auto;
  background-color: white;
  margin: -0.3em 0.5em 2.5em 0.5em;
}

.heading {
  margin-block: auto;
}

.heading h1 {
  font-size: calc(2.5vw + 1.5px);
  font-weight: 900;
  text-align: center;
  color: black;
  font-family: "Libre Baskerville", serif;
}

.heading h2 {
  margin-top: 0.5em;
  font-size: calc(3vw + 1.5px);
  text-align: center;
  color: #ff6348;
  font-family: "Eczar", serif;
}

.moreBtn {
  margin-top: 3rem;
}

.moreBtn a {
  text-decoration: none;
  align-items: center;
  color: black;
  margin: auto;
  display: block;
}

.btn {
  font-size: calc(1.5vw + 1px);
  padding: 0.2em 0.7em;
  height: fit-content;
  width: max-content;
  display: inline;
}

.description p {
  margin: 2% auto 0 auto;
  padding: 1.5em 0.5em;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  text-indent: 4.5rem;
  line-height: 3.4rem;
  width: 98%;
  color: black;
  font-size: 1.7rem;
  font-family: "Merienda", cursive;
}

/* Services Summary*/

.ServiceSummary {
  height: auto;
  background-color: #353b48;
  padding: 1.8rem 0 5rem 0;
}

.ServiceSummary h1 {
  margin: auto auto;
  font-size: calc(2.5vw + 4px);
  padding: 0.4em;
  text-align: center;
  color: white;
  font-family: "Eczar", serif;
}

.ServiceSummary .flex-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: center;
  height: auto;
}
.ServiceSummary .flex-item {
  background: #191919;
  width: 22rem;
  height: 32rem;
  margin-top: 1rem;
  line-height: 15rem;
  color: #ffffff;
  text-align: center;
  border: 0.1rem solid gray;
}

.ServiceSummary .flex-item h1 {
  font-size: 2rem;
  text-align: center;
  color: #fbc531;
  margin-top: 2.4rem;
}

#divider {
  width: 20%;
  border-top: dotted 0.4rem gray;
  margin-top: 2rem;
}

.ServiceSummary .flex-item h6 {
  font-size: 1.6rem;
  width: 85%;
  margin: auto;
  text-align: inherit;
  margin-top: 2.5rem;
}

.flex-item a {
  display: block;
  margin-top: -25px;
  color: #718093;
  font-size: 1.5rem;
  font-weight: bold;
}

.flex-item a:hover {
  color: #fff;
}

.ServiceSummary .flex-item:hover {
  background: #000000;
}

.ServiceSummary a {
  text-decoration: none;
}
.flex-item a {
  border-bottom: -50px;
}

.flex-item a:hover {
  color: #fbc531;
  font-size: 1.8rem;
  border-bottom: 2px solid #fbc531;
}

/* Industries */
.industries {
  height: auto;
  background-color: white;
  padding: 1.8rem 0 5rem 0;
}

.industries h1 {
  text-align: center;
  margin-top: 0.2em;
  padding: 0.4em;
  color: #341f97;
  font-size: calc(2.5vw + 4px);
  font-family: "Eczar", serif;
}

.industries .flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flex-container .item {
  background-color: #191919;
  border-radius: 50%;
  color: hsl(0, 0%, 100%);
  margin: 1.5rem 1.5rem;
  text-align: center;
  height: 17rem;
  width: 17rem;
  order: 2;
  align-self: center;
  flex-grow: 0;
  -webkit-box-shadow: -0.2rem -0.11rem 2.5rem 0.5rem rgba(26, 23, 86, 0.75);
  box-shadow: -0.2rem -0.1rem 2.5rem 0.5rem rgba(26, 23, 86, 0.75);
}

.flex-container .item:hover {
  background-color: #000000;
  margin: 1rem;
}

.item {
  height: 40rem;
  width: 40rem;
  transform: translate(0%, 0%);
  overflow: hidden;
  margin-left: 100px;
}

.overlay {
  height: 17rem;
  width: 17rem;
  background: rgba(68, 0, 255, 0.562);
  position: absolute;
  top: 0;
  left: 40rem;
  transition-property: left;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item:hover .overlay {
  left: 0;
}

.text {
  display: block;
  margin-left: 0;
}

.text .btn {
  padding-inline: 2.4rem;
  padding-block: 0.4em;
  font-size: 1.7rem;
  margin-top: 2.5rem;
}

.text a {
  text-decoration: none;
}

.item h2 {
  font-size: 1.5rem;
  padding-top: 1rem;
}

#customIcon {
  font-size: 5rem;
  margin: 2.5rem auto 1rem auto;
  color: #575fcf;
}

/* common Headers */

.commonHeader {
  height: auto;
  padding: 6rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.leftCommon {
  width: 50%;
}

.leftCommon h1 {
  width: 80%;
  font-size: calc(3.8vw + 2px);
  margin-top: 0.2em;
  margin-left: 1.4em;
  font-weight: bolder;
  letter-spacing: 0.2rem;
  text-align: center;
  font-family: "Libre Baskerville", serif;
}

.rightCommon {
  width: 50%;
}

.rightCommon h2 {
  width: 80%;
  font-size: calc(1.5vw + 1.5px);
  margin-top: 0.4em;
  margin-left: 4em;
  letter-spacing: 0.05em;
  text-align: center;
}

.rightCommon h6 {
  width: 85%;
  font-size: calc(1.2vw + 1.2px);
  margin-top: 6%;
  margin-left: 4em;
  text-align: center;
}

/* Services */
.servicePage {
  height: auto;
  background-repeat: repeat;
}

.serviceHeader {
  background-color: #2f3542;
}

.leftService h1 {
  color: white;
}

.rightService h2 {
  color: #eccc68;
  font-family: initial;
}

.rightService h6 {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* .card {
  margin-bottom: 1.5rem;
  background-color: #2d3436;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
} */

.card {
  background: rgba(76, 80, 116, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 4.5rem;
}

.allCards {
  background-image: url("./img/ServicesImages/allCardsbgImg.PNG");
  padding: 2rem;
}

.card img {
  width: 100%;
  height: max(15vw, 150px);
}

.card-title {
  text-align: center;
  color: #feca57;
  font-size: calc(1vw + 1rem);
  font-family: "Merienda";
}

.card-text {
  width: 85%;
  /* font-size: calc(1vw + 1.3px); */
  font-size: calc(0.7vw + 0.6rem);
  text-align: justify;
  text-indent: 25px;
  margin: 15px auto;
  color: white;
}

#key_features {
  font-size: calc(0.9vw + 0.7rem);
  text-align: center;
  padding-top: 25px;
  color: #f1cb4d;
  font-family: "Merienda";
}

/* .list-group-item {
  padding: 7px;
  text-align: center;
  background-color: #2d3436;
  color: white;
  font-size: calc(0.5vw + 0.7rem);
} */

.list-group-item {
  background: rgba(76, 80, 116, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: white;
  font-size: calc(0.7vw + 0.5rem);
  padding: 7px;
  text-align: center;
}

.callIcon {
  height: 1em;
}

.KnwmoreBtn button {
  margin: 1.5rem auto 0 auto;
}

.KnwmoreBtn a {
  text-decoration: none;
}

.service_btn {
  border-radius: 45em;
  font-size: calc(1vw + 1px);
  height: fit-content;
  width: max-content;
  display: inline;
}

.bestServices {
  border: 0.2rem solid #2980b9;
  margin: 1em;
  background-color: white;
  color: black;
  text-align: center;
}

.bestServices h1 {
  font-size: calc(3vw + 2px);
  font-weight: 900;
  margin: auto;
  padding: 0.2em;
  font-family: "Courgette";
}

.bestServices a {
  text-decoration: none;
  display: block;
  padding: 0.2em;
}

.bestServices h3 {
  font-size: calc(2vw + 1px);
  font-family: "Courgette";
}

.bestServices h3:hover {
  color: #74b9ff;
}

/* Footer */

.footerContainer {
  display: flex;
  flex-direction: row;
}

.footer-side-icon {
  width: 33.33%;
}

.footer-widget {
  width: 33.33%;
}

.footer-center {
  width: 33.33%;
}

.footer-section {
  background: #151414;
  height: auto;
  padding: 2rem;
}

.single-cta {
  margin: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all-cta {
  margin-left: 3vw;
}

.icon {
  color: #ff5e14;
}

.cta-text {
  padding-left: 2em;
  margin-top: 1em;
}

.cta-text h4 {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.cta-text span {
  text-decoration: none;
  list-style-type: none;
  color: #757575;
  font-size: 1.5rem;
}

.footer-logo {
  display: flex;
  justify-content: center;
}

.footer-logo img {
  width: 12vw;
  height: 12vw;
}

.footer-text p {
  font-size: 2rem;
  color: #7e7e7e;
  margin: 0 auto;
  text-align: center;
  font-family: "Courgette";
}

.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.6rem;
}

.socialIcons .Sicon {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 40%;
  margin-inline: 1.5rem;
  margin-top: 1.8rem;
}

.facebook-bg {
  color: #e84118;
}

.facebook-bg:hover {
  opacity: 0.5;
}

.LinkedIn-bg {
  color: #e1b12c;
}

.LinkedIn-bg:hover {
  opacity: 0.5;
}

.WhatsApp-bg {
  color: #4cd137;
}

.WhatsApp-bg:hover {
  opacity: 0.5;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin: 2rem auto;
}

.footer-widget ul {
  padding-left: 0;
}

.footer-widget ul li {
  list-style-type: none;
  padding: 1rem 0 0.5rem 0;
  color: white;
  font-size: 1.7rem;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  text-align: center;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
  text-decoration: none;
  font-weight: bold;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.copyright-text p {
  margin: auto;
  font-size: calc(1.1vw + 0.5px);
  color: #878787;
  display: block;
  text-align: center;
}

/* about */
.container1 section {
  color: #000000;
  padding: 5px 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("./img/AboutPage/Office_bg_img.PNG");
}

.container2 section {
  color: #000000;
  padding: 10px 0;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("./img/ContactPage/telephone_bg.PNG");
}

.divider2 {
  width: 100%;
  padding: 0;
  margin: 10vw 0;
}

.companyName {
  width: 35ch;
  animation: typing 4.5s steps(40);
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 0.1em solid; */
  font-family: "Merienda", cursive;
  padding-bottom: 0.5em;
  margin: auto;
}

.divider2 h1 {
  text-align: center;
  font-size: calc(3vw + 1px);
  color: #fff;
  margin-top: -2em;
  font-weight: 900;
}

.divider2 p {
  margin-top: 2em;
  text-align: center;
  font-size: calc(1.8vw);
  color: #e1b12c;
  font-weight: 500;
}

@keyframes typing {
  from {
    width: 0;
  }
}

.companyIntro {
  width: 55ch;
  animation: typing 8s steps(40);
  white-space: nowrap;
  overflow: hidden;
  /* border-right: 0.1em solid; */
  font-family: "Merienda", cursive;
  margin: auto;
}

@keyframes typing {
  from {
    width: 0;
  }
}

#mainHeading {
  text-align: center;
  color: #3742fa;
  font-weight: bolder;
  font-size: calc(2.5vw + 1.5px);
  font-family: "Courgette";
}

.aboutContainer {
  height: auto;
  display: flex;
  flex-direction: row;
}

.leftsideImg {
  width: 40%;
}

.leftsideImg img {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  margin-top: 2em;
  margin-left: 18rem;
  max-width: 100%;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0rem);
  }
  50% {
    transform: translatey(1rem);
  }
  100% {
    transform: translatey(0rem);
  }
}

.rightDesc {
  width: 55%;
}

.rightDesc p {
  width: 90%;
  text-align: justify;
  font-size: 1.5rem;
  margin-top: 3.5em;
  text-indent: 7rem;
  line-height: 2.2rem;
  border-radius: 40px;
  padding: 15px;
}

.leftDesc {
  width: 55%;
}

.leftDesc p {
  width: 80%;
  text-align: justify;
  font-size: 1.5rem;
  margin-left: 120px;
  margin-top: 50px;
  text-indent: 70px;
  line-height: 2.2rem;
  border-radius: 40px;
  padding: 25px;
}

.rightsideImg {
  width: 35%;
}

.rightsideImg img {
  width: 18vw;
  height: 18vw;
  margin-left: 10rem;
  margin-top: 1rem;
  max-width: 100%;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.aboutDesc p {
  border: 3px solid #8e44ad;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
}

.missionDesc p {
  border: 3px solid #e67e22;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
}

.visionDesc p {
  border: 3px solid #487eb0;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
}

.valueDesc p {
  border: 3px solid #4cd137;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: bold;
}

#dottedLine {
  width: 10%;
  border-top: dotted 2px #7f8c8d;
  margin-top: 1.5em;
}

.letsTalk {
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 3em 0 0 0;
  margin-bottom: 40px;
}

.leftImg {
  width: 65%;
  height: auto;
  background-image: url("./img/AboutPage/leftbgimg.PNG");
}

.leftImg h6 {
  color: white;
  margin-top: 1.5em;
  margin-left: 5.5em;
  font-size: calc(1.5vw + 2px);
}

.leftImg h1 {
  color: white;
  margin-top: 1em;
  margin-left: 2.8em;
  font-size: calc(3vw + 2.5px);
  width: 70%;
}

.service_btn {
  margin-left: 8em;
  color: white;
  font-size: calc(1vw + 2px);
  display: block;
  margin-top: 2.5em;
}

.rightImg img {
  height: auto;
}

/* Career */

.careerHeader {
  background-color: #c8d6e5;
}

.rightCareer h2 {
  color: #30336b;
  font-family: initial;
  font-size: 2.8em;
  margin-left: 2.5em;
}

.rightCareer h6 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.8em;
}

#officeImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hiring {
  margin-bottom: 25px;
  margin-top: 45px;
  /* border: 2px solid blue; */
}

.Join_US {
  display: flex;
  flex-direction: column;
}

.JoinUsImg {
  clip-path: inset(1.2em 0px 0px 0px);
  width: 100%;
}

.JoinUsImg img {
  width: 70vw;
  height: 20vw;
  display: block;
  align-items: center;
  margin: 0 auto;
}

.jobBoard {
  clip-path: inset(7em 0px 0px 0px);
}
.powr-job-board {
  position: relative;
  bottom: 7em;
  width: 100%;
  margin: auto;
}

.hiringHeader {
  background-color: #c8d6e5;
}

.leftHiring h1 {
  font-size: calc(3vw + 1px);
}

.rightHiring h2 {
  color: #30336b;
  font-family: initial;
  font-size: 2.6em;
  margin-left: 3em;
}

.rightHiring h6 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.8em;
}

/* contact */

.conactBorder {
  margin-top: 45px;
  margin-bottom: 25px;
}

.divider {
  width: 100%;
  padding: 0;
  margin: 10vw 0;
  font-family: "Merienda", cursive;
}

.divider h1 {
  text-align: center;
  font-size: calc(3vw + 1px);
  color: #6ab04c;
  font-family: "Eczar";
  letter-spacing: 0.1em;
  font-weight: 900;
}

.divider p {
  margin: 2em auto 0 auto;
  text-align: center;
  font-size: 2vw;
  color: rgba(255, 255, 255, 0.664);
  font-weight: 500;
  width: 70%;
}

.contactHeader {
  /* background-color: #f6e58d; */
  background-color: #7dad6883;
}

.leftContact h1 {
  font-size: calc(3vw + 1px);
}

.rightContact h2 {
  color: #30336b;
  font-family: initial;
  font-size: 2.7em;
  margin-left: 3em;
}

.rightContact h6 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.7em;
}

.form {
  display: flex;
  flex-direction: row;
}

.powr-contact-form {
  clip-path: inset(2em 0 10em 0);
  margin-top: 1.3em;
  margin-bottom: -7em;
  width: 50%;
}

.formImg {
  width: 50%;
  margin-top: 10rem;
}

.formImg img {
  display: block;
  margin: auto;
  border-radius: 15px;
  width: 75%;
  height: 60%;
}

@media (max-width: 770px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
  }

  .footer-side-icon {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 2rem;
  }

  .footer-center {
    width: 100%;
  }

  .cta-text h4 {
    width: 100%;
    font-size: 1.5em;
  }
  .single-cta {
    margin: 0.8rem;
  }
  .mailCall {
    margin-bottom: 1rem;
  }

  .cta-text span {
    font-size: 1.4em;
  }

  .footer-text p {
    font-size: 1rem;
    font-family: "Courgette";
  }

  .footer-widget {
    width: 100%;
  }

  .footer-widget-heading h3 {
    display: none;
    /* font-size: 1.2em;
    margin: 1.5rem 0 0 0; */
  }

  .footer-widget ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .footer-widget ul li {
    font-size: 1.2em;
    margin: 0 1.2rem;
  }

  .copyright-text p {
    font-size: 2vw;
  }

  .navbar a {
    font-size: 1.5rem;
    text-transform: capitalize;
    text-align: center;
  }

  .description p {
    margin: -1% auto 0 auto;
    padding: 0 0.5em;
    line-height: 2.8rem;
    font-size: 1.3rem;
  }

  .moreBtn {
    margin-top: 2rem;
    text-align: center;
  }

  .service_btn {
    font-size: 1rem;
  }

  .commonHeader {
    display: block;
    width: 100%;
    padding: 2rem;
  }

  .leftCommon {
    width: 100%;
  }

  .rightCommon {
    width: 100%;
  }

  .leftCommon h1 {
    font-size: 2rem;
    width: 80%;
    margin: auto;
  }

  .rightCommon h2 {
    width: 80%;
    margin: 20px auto;
    padding-inline: 10px;
    text-align: center;
    font-size: 1.4rem;
    font-family: "Akaya Kanadaka", cursive;
  }

  .rightCommon h6 {
    width: 80%;
    font-size: 1.2rem;
    margin: 20px auto;
    text-align: center;
  }

  .card-text {
    font-size: 1.2rem;
  }

  .list-group-item {
    font-size: 1.1rem;
  }

  .card {
    width: 75%;
    margin: 1rem auto 1.5rem auto;
  }

  .abtContainer,
  .visionContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .missionContainer,
  .valueContainer {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .leftsideImg {
    width: 100%;
  }

  .rightsideImg {
    width: 100%;
  }

  .leftsideImg img {
    margin: 0 auto;
    text-align: center;
  }

  .rightsideImg img {
    margin: 0 auto;
    text-align: center;
  }

  .rightDesc {
    display: block;
    width: 90%;
  }

  .leftDesc {
    display: block;
    width: 90%;
  }

  .rightDesc p {
    width: 100%;
    margin: 0 auto;
    font-size: 1.3rem;
  }

  .leftDesc p {
    width: 100%;
    margin: 0 auto;
    font-size: 1.3rem;
  }

  .rightImg {
    display: none;
  }

  .leftImg {
    width: 100%;
  }

  .leftImg h6 {
    font-size: 1.5rem;
    text-align: center;
    margin-left: 0;
  }

  .leftImg h1 {
    font-size: 2rem;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .service_btn {
    margin: 2em auto 1em auto;
    color: white;
    font-size: 1.2rem;
    display: block;
  }

  .rightImg img {
    height: auto;
  }

  .powr-job-board {
    width: 80%;
  }

  .formImg {
    width: 0;
  }

  .formImg img {
    display: none;
  }

  .powr-contact-form {
    clip-path: inset(2em 0 7em 0);
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 480px) {
  /* Navbar */
  .navbar-brand img {
    width: 8rem;
    height: 6rem;
  }

  .nav-link {
    font-size: 1.2rem !important;
  }

  .dropdown-item {
    font-size: 1rem !important;
  }

  /* About us */
  .description p {
    margin: -1% auto 0 auto;
    padding: 0 0.5em;
    line-height: 2.3rem;
    font-size: 1.1rem;
  }

  .moreBtn {
    margin-top: 1rem;
    text-align: center;
  }

  /* Service Summary */

  .ServiceSummary h1 {
    font-size: 1.5rem;
  }

  .ServiceSummary .flex-item {
    width: 16rem;
    height: 20rem;
  }

  .ServiceSummary .flex-item h1 {
    font-size: 1.4rem;
    margin-top: 1.2rem;
  }

  #divider {
    width: 20%;
    border-top: dotted 0.2rem yellow;
    margin-top: 1.2rem;
  }

  .ServiceSummary .flex-item h6 {
    font-size: 1.1rem;
    width: 75%;
    margin: auto;
    margin-top: 1.2rem;
  }

  .flex-item a {
    margin-top: -4.5rem;
    font-size: 1.2rem;
  }

  /* Industries */
  .industries h1 {
    font-size: 1.5rem;
  }

  .flex-container .item {
    height: 8rem;
    width: 8rem;
    margin-left: 2rem;
  }

  .overlay {
    height: 8rem;
    width: 8rem;
    left: 40rem;
  }

  .text .btn {
    padding-inline: 1.4rem;
    padding-block: 0.4em;
    font-size: 1rem;
    margin-top: 3rem;
  }

  .text a {
    text-decoration: none;
  }

  .item h2 {
    font-size: 1rem;
    padding-top: 0.5rem;
  }

  #customIcon {
    font-size: 3rem;
    margin: 1.5rem auto 0 auto;
  }

  /* servicePage */
  .ServiceSummary .card {
    width: 60%;
  }

  .card {
    width: 100%;
    margin: 1rem auto 1.5rem auto;
  }

  .leftCommon h1 {
    width: 100%;
    font-size: 1.5em;
  }

  .rightCommon h2 {
    width: 100%;
    font-size: 1.3em;
    margin-top: 1em;
    letter-spacing: 0.05em;
  }

  .rightCommon h6 {
    width: 100%;
    font-size: 1.1em;
    margin-top: 1em;
  }

  /* aboutPage */
  .leftsideImg img {
    margin-left: 2em;
  }

  .rightsideImg img {
    margin-left: 2em;
  }

  .leftImg h6 {
    font-size: 1.5em;
    font-weight: bold;
  }

  .leftImg h1 {
    font-size: 1.3em;
    width: 100%;
  }

  .service_btn {
    font-size: 0.8em;
    display: block;
    margin-top: 1em;
  }

  /* careerPage */

  .JoinUsImg {
    display: none;
  }

  .jobBoard {
    position: relative;
    bottom: 6em;
  }

  /* contactPage */
  .leftContact h1 {
    font-size: 1.3rem;
  }

  .rightContact h2 {
    font-size: 1.5rem;
    width: 100%;
  }

  .rightContact h6 {
    font-size: 1.2rem;
    width: 100%;
  }

  /* footer */
  .cta-text {
    padding-left: 2em;
    margin-top: 0.5em;
  }

  .cta-text h4 {
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
  }

  .cta-text span {
    font-size: 1.1rem;
  }
  .footer-widget ul li {
    font-size: 1em;
    margin: 0 1.2rem;
  }
}
